import React from 'react'
import {Helmet} from "react-helmet"

const MetaHelmet = (props) => {
  return (
    <Helmet>
    {/* <title>{}</title> */}
    <meta property="og:title" content={props.ogTitle} />
      <meta property="og:description" content={props.ogDescription} />
      <meta property="og:image" content={props.ogImage} />
  </Helmet>
  )
}

export default MetaHelmet
