import React from 'react'
import Post from './Post'
import "./Post.css"

const PostsContainer = (props) => {
  return (
    <div className='postsContainer'>
    {props.posts.map(post=>{
      return(
        <Post key = {post.postId} post={post}/>
      )
    })}
    </div>
  )
}

export default PostsContainer
