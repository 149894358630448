import React from 'react'
import {AiOutlineSearch} from "react-icons/ai"
import "./Search.css"
const Search = (props) => {
  return (
    <div className='search-input-container'>
    <p className='searchBlog'>Blog</p>
    <p className=''>Find all of our latest stories</p>
    <div className='search-input'>
      <label>
        <input onChange={(e)=>{
          console.log(e.target.value)
          props.searchQuery(e.target.value)
        }} type='search' placeholder='search blogs'/>
        <AiOutlineSearch className='search-icon' size={30} fill='#61ea97'/>
      </label>
</div>
    </div>
  )
}

export default Search
