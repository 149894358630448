import React, { useState } from 'react'
import "./Header.css"
import {GiHamburgerMenu} from "react-icons/gi"
import {AiOutlineClose} from "react-icons/ai"
const Header = (props) => {
    const [handleMenu, setHandleMenu] = useState('')
    // const handleCategory = (category) =>{
    //   props.onChangeCategory(category)
    //   setHandleMenu('')
    // }


    // onClick={(e)=>{
    //   e.preventDefault();
    //   handleCategory('Home')
    // }}
  return (
    <div className='header-container'>
    <div className='header-title'>
    <div className='header-logo'>
    <div className='headerImageContainer'>
    <img src='https://cappriciosec.com/logo.png' alt=''/>
    </div>
    <p>Cappricio Securities</p>
    </div>
    <div>
   {handleMenu === '' ?  <GiHamburgerMenu onClick={()=>{
        handleMenu === '' ? setHandleMenu('after-height') : setHandleMenu('')
    }} className='menu-icon'/> :  <AiOutlineClose onClick={()=>{
        handleMenu !== '' ? setHandleMenu('') : setHandleMenu('after-height')
    }} className='menu-icon'/>}
    </div>
    </div>
    <div className={`header-navbar ${handleMenu} `}>
    <nav className='nav'>
            <ul className='nav-list'>
            <li><a href='/'>HOME</a></li>
                <li><a  href='/application'>APPLICATION</a></li>
                <li><a href='/tools'>TOOLS</a></li>
                <li><a href='/hardware'>HARDWARE</a></li>
                <li><a href='/cve'>CVE</a></li>
            </ul>
        </nav>
    </div>
  </div>
  )
}

export default Header
