import React from 'react'
import "./Post.css"

const Post = (props) => {

  return (
   <div className='postContainer'>
      <div className='postImageContainer'>
          <img src={props.post.postImage} alt='' />
      </div>
      <h3>{props.post.postTitle}</h3>
      <p>Published on: {props.post.postDate}</p>
      <p className='postContainerDescription'>{props.post.postDescription}.</p>
      <a href={`/${props.post.postCategory}/${props.post.postId}/${props.post.postTitle}`}>Read more</a>
   </div>
  )
}

export default Post
