import React, { useState } from 'react';
import './AdminLogin.css';
import axios from "axios"
import { useEffect } from 'react';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  // useEffect(()=>{
  //   if(JSON.parse(localStorage.getItem('admin'))){
  //     window.location.href="/admin"
  //   }
  // })

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform login authentication or further actions here
    console.log('Email:', email);
    console.log('Password:', password);

    try {
      const formData = new FormData();
      formData.append("admin", email);
      formData.append("password", password);
      const response = await axios.post(`https://blogs.cappriciosec.com/login.php`
      , formData); // Adjust the API endpoint URL accordingly
     if(response.data === 'proceed'){
      console.log(response.data)
      localStorage.setItem('admin',JSON.stringify('admin'))
      window.location.href = "/admin"
      setEmail('');
      setPassword('');
     }
     else{
      alert("enter the correct password")
      console.log(response.data);
     }
    } catch (error) {
      // Handle error
    }


    // Reset form fields
   
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2 className="login-title">Login</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
              required
            />
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
