import axios from 'axios';
import React, { useState } from 'react';
import './Footer.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Fragment } from 'react';

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send the email to your backend or perform any other desired action
    const sendMail = async () =>{
      const formData = new FormData();
      formData.append('receiver', email)
     const response =  axios.post('https://blogs.cappriciosec.com/PHPMailer.php', formData)
     console.log(response)
    }
    sendMail()
    setEmail('');
    toast.success("Subscribed successfully")
  };

  return (
    <Fragment>
    <footer className="footer">
      <div className="footer-content">
        <div className="newsletter">
        <p>Stay connected</p>
          <h2>Subscribe for more Security content</h2>
          <form className='newsLetterForm' onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <button type="submit">Subscribe</button>
          </form>
        </div>
       
      </div>
      <div className="footer-bottom">
        <p>&copy; 2023 CappricioSec. All rights reserved.</p>
      </div>
    </footer>
    <ToastContainer />
    </Fragment>
  );
};

export default Footer;
