import React,{useEffect, useState } from 'react'
import {  useParams} from 'react-router-dom'
import Footer from '../Footer/Footer'
import "./PostContent.css"
import axios from "axios"
import Header from '../Header/Header'
import Error404 from './Error404'
import { RotatingLines } from 'react-loader-spinner'
import ProgressBar from "./ProgressBar"
import MetaHelmet from '../MetaHelemet/MetaHelmat'
import MetaTags from 'react-meta-tags';

const PostContent = () => {
  const {postID, title} = useParams()
  console.log(postID, title)
  const [postContent, setPostContent] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const monthName = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  useEffect(() => {
    const fetchPostContent = async() =>{
      const formData = new FormData()
      formData.append('postId', postID)
      await axios.post("https://blogs.cappriciosec.com/PostContent.php", formData)
      .then(response =>{
        console.log(response.data)
  
        if(response.data.status === 'success'){
         setPostContent(response.data.postContent)
         setIsLoading(false)
         // document.title = response.data.postContent.postTitle;
         // const ogTitleMetaTag = document.querySelector('meta[property="og:title"]');
         // if (ogTitleMetaTag) {
         //   ogTitleMetaTag.setAttribute('content', response.data.postContent.postTitle);
         // }
     
         // const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
         // if (ogImageMetaTag) {
         //   ogImageMetaTag.setAttribute('content', response.data.postContent.postImage);
         // }

         // const ogDescriptionMetaTag = document.querySelector('meta[property="og:description"]');
         // if (ogDescriptionMetaTag) {
         //   ogDescriptionMetaTag.setAttribute('content', response.data.postContent.postDescription);
         // }
        }
        else{
          setIsLoading(false)
        }
      })
    }
    fetchPostContent();
    return ()=>{
      // const ogTitleMetaTag = document.querySelector('meta[property="og:title"]');
      // if (ogTitleMetaTag) {
      //   ogTitleMetaTag.setAttribute('content', "Cappriciosecurities Blog");
      // }
  
      // const ogDescriptionMetaTag = document.querySelector('meta[property="og:description"]');
      // if (ogDescriptionMetaTag) {
      //   ogDescriptionMetaTag.setAttribute('content', "describe the cyber security");
      // }

      
      // const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
      // if (ogImageMetaTag) {
      //   ogImageMetaTag.setAttribute('content', "https://cappriciosec.com/logo.png");
      // }
    }
  },[]);

 
 
  return (
    <>
    <MetaTags>
      <title>{postContent?.postTitle?.replaceAll("_"," ")}</title>
      <meta id="meta-description" name="description" content={postContent?.postDescription} />
      <meta id="og-title" property="og:title" content={postContent?.postTitle} />
      <meta id="og-image" property="og:image" content={postContent?.postImage} />
    </MetaTags>
    <ProgressBar/>
    <Header/>
  {isLoading ? <div className='preloader'>
    <RotatingLines
  strokeColor="#61ea97"
  strokeWidth="5"
  animationDuration="0.75"
  width="96"
  visible={true}
/> 
  </div>: postContent !== null ?  <div className='postContentContainer'>
    <MetaHelmet ogTitle = {postContent.postTitle} ogDescription = {postContent.postDescription}  ogImage = {postContent.postImage}/>
    <div className='postContentTop'>
      <h4 className='postContentCategory'>{postContent.postCategory.toUpperCase()}</h4>
      <h2 className='postContentTitle'>{postContent.postTitle}</h2>
      <p className='postContentDate'>{monthName[Number(postContent.postDate.slice(5,7))-1] + " " + postContent.postDate.slice(8) + ", " + postContent.postDate.slice(0,4)}</p>
      <div className='postContentImageContainer'>
      <img className='postContentImage' src={postContent.postImage} alt='' />
      </div>
    </div>
    <div dangerouslySetInnerHTML={{ __html: postContent.postContent}} className='postContentBody'>
      </div>
    </div> : <Error404/>}
     <Footer/>
    </>
  )
}

export default PostContent
