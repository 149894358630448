import React, { useState, useRef, useEffect } from 'react';
// import TextEditor from './TextEditor';
import JoditEditor  from 'jodit-react'
import "./AddPost.css"
import axios from "axios"
import { toast } from 'react-toastify';

const AddPost = (props) => {
    const {postId, postTitle, postImage, postDescription, postContent, postCategory, edit} = props.onEditData;
    const [id, setId] = useState(null)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [category, setCategory] = useState("")
    const [content, setContent] = useState("")
    const editor = useRef(null);
    useEffect(() => {
      if (edit === true) {
        setId(postId);
        setTitle(postTitle);
        setImage(postImage);
        setDescription(postDescription);
        setCategory(postCategory);
        setContent(postContent);
      }
    }, [edit, postId, postTitle, postImage, postDescription, postContent, postCategory]);
  const addPostHandler = (endpoint) =>{
      const addPosts = async () => {
        try {
          const formData = new FormData();
          formData.append('postId', id);
          formData.append('postTitle', title);
          formData.append('postImage', image);
          formData.append('postDescription', description);
          formData.append('postContent', content);
          formData.append('postCategory', category);
          const response = await axios.post(`https://blogs.cappriciosec.com/${endpoint}.php`
          , formData); // Adjust the API endpoint URL accordingly
          if(endpoint === 'AddPost' ){
            toast.success("Post Added Successfully")
            console.log(response.data)
          }
          else{
            toast.success("Post Updated Successfully")
          }

        } catch (error) {
          // Handle error
        }
      };
  
        addPosts();

    }
  return (
    <div className='addPost-wrapper'>
    <div className='addPost-content'>
    <div className='input-field'>
    <label htmlFor='title'>
    Post Title
    </label>
    <input 
    value = {title}
    onChange = {(newTitle)=>setTitle(newTitle.target.value)}
    type="text" placeholder = "Enter title" name='title'/>
    </div>
    <div className='input-field'>
    <label htmlFor='description'>
    Post Description
    </label>
    <input type="text"
    value = {description}
    onChange = {(newDescription)=>setDescription(newDescription.target.value)}
     placeholder = "Enter description" name='description'/>
    </div>
    <div className='input-field'>
    <label htmlFor='image'>
Post Image
    </label>
    <input 
    value = {image}
    onChange = {(newImage)=>setImage(newImage.target.value)}
    type="text" placeholder = "Enter the image url" name='image'/>
    </div>
    <div className='input-field'>
    <label htmlFor='category'>
Post Category
    </label>
    <input
    value = {category}
    onChange = {(newCategory)=>setCategory(newCategory.target.value)}
     type="text" placeholder = "Enter the category" name='category'/>
    </div>
    <div className='input-field'>
    <label htmlFor='category'>
Post Content
    </label>
    <JoditEditor
      ref = {editor}
      value = {content}
      name = "category"
      className='text-editor'
      onChange = {newContent=>setContent(newContent)}
      />
    </div>
    <div className='input-field'>
 
    <button onClick={
      ()=>{
        if(edit !== true){
          console.log("add")
        if(image && title && category && description && content){
          
        addPostHandler('AddPost')
        const bool = true
      props.onCancel(bool)

      }
      else{
        alert("All the fields are required")
      }
    }
else{
  console.log("edit")
  const bool = true
      addPostHandler('UpdatePost')
      props.onCancel(bool)


}
      }
    } className='post-button'>{edit === true ? 'UPDATE POSTS' : 'CREATE POST'}</button>
    <button className='post-button' onClick={()=>{
      const bool = true
      props.onCancel(bool)
    }} style={{'backgroundColor':'red'}}>CANCEL</button>
    </div>
    </div>

    </div>
  )
}

export default AddPost
