import React, {useState, useEffect, Fragment} from 'react'
import "./ViewPost.css"
import axios from "axios"
import { FaSearch } from 'react-icons/fa'
import { useRef } from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ViewPost = (props) => {
    const [search, setSearch] = useState("")
    const query = useRef(null)
    const handleSearch =() =>{
        const filteredPosts = dataReference.filter((post)=> post.postTitle.toLowerCase().includes(String(query.current.value).toLowerCase()))
        if(query === ""){
            setPosts(dataReference)
        }
        setPosts(filteredPosts)

    }
    const [dataReference, setDataReference]  = useState([])
    const [posts, setPosts] = useState([]);
    useEffect(() => {
      
      fetchPosts();

    },[]);

    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://blogs.cappriciosec.com/adminPosts.php'); // Adjust the API endpoint URL accordingly
        if(response.data === 'Not an admin...'){
          console.log(response.data)
            window.location.href = '/adminlogin'
        }
       else{
        setPosts(response.data);
        setDataReference(response.data)
       }
        console.log(response)
      } catch (error) {
        // Handle error
      }
    };


    const removeRow = (id) => {
        const updatedData = posts.filter((post) => post.postId !== id);
        setPosts(updatedData);
        setDataReference(updatedData)
        fetch('https://blogs.cappriciosec.com/Delete.php', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: id }),
          })
            .then(response => response.text())
            .then(result => {
              console.log(result); // Output the result to the console
              // Handle the result as needed
              toast.error("Post deleted successfully.");
              fetchPosts()
            })
            .catch(error => {
              console.error('Error:', error);
              // Handle errors
            });
    

      };
      if(!posts){
        return <h1>Hi</h1>;
      }
  return (
    <Fragment>
    <div className='viewPost-wrapper'>
    <div className='viewPost-content'>
    <div className='add-button'>
    <button onClick={()=>{
        const bool = false
        props.onActiveAdd(bool)
         }}>ADD NEWPOST</button>
         <div className='post-search'>
        <label>
            <input
            value = {search}
            ref = {query}
            onChange = {(e)=>{
              setSearch(e.target.value)
              handleSearch()
            }}
             type="text" placeholder = "Search"/>
            <FaSearch  className='post-search-icon'/>
        </label>
      </div>
    </div>
      <div className='posts-crud'>
        <table>
        <thead>
            <tr>
                <th>
                    S. NO
                </th>
                <th>
                    POST IMAGE
                </th>
                <th>
                    POST TITLE
                </th>
                <th>
                    VIEW
                </th>
                <th>
                    EDIT
                </th>
                <th>
                    DELETE
                </th>
              
            </tr>
        </thead>
            <tbody>
               {posts.map((post,index)=>{
                return(
                    <tr  key={post.postId}>
                    <td>{index+1}</td>
                    <td><img className='post-img' src={post.postImage} alt=''/></td>
                    <td>{post.postTitle}</td>
                    <td><button onClick={()=>{
                        window.location.href = `/blog/${post.postId}/${post.postTitle}`
                    }}>
                        VIEW
                    </button></td>
                    <td><button
                    onClick={()=>{
        const bool = false
        const data = {
            'postId' : post.postId,
            'postTitle' : post.postTitle,
            'postImage' : post.postImage,
            'postDescription' : post.postDescription,
            'postCategory' : post.postCategory,
            'postContent' : post.postContent,
            'bool' : bool,
            'edit' : true,
        }
        props.onActiveEdit(data)
         }}
                    >
                        EDIT
                    </button></td>
                    <td>
                    <button
                    onClick={()=>{
                        removeRow(post.postId)
                    }}
                    >
                        DELETE
                    </button>
                    </td>
                </tr>
                )
               })}
            </tbody>
        </table>
      </div>
    </div>
    </div>
    <ToastContainer />
    </Fragment>
  )
}

export default ViewPost
