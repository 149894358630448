import React from 'react'
import PostsContainer from '../components/Posts/PostsContainer'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import Search from '../components/Search/Search'
// import MetaHelmet from '../components/MetaHelemet/MetaHelmat'
import MetaTags from 'react-meta-tags';


const Hardware = () => {
  const [posts, setPosts] = useState([])
//   const [refPosts, setRefPosts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(()=>{
      fetchPosts()
    }
,[])
  
  const fetchPosts = async() =>{
    const formData = new FormData();
    formData.append('category','hardware')
    await axios.post("https://blogs.cappriciosec.com/posts.php", formData)
    .then(response =>{
      console.log(response.data)

      if(response.data.status === 'success'){
        setPosts(response.data.posts)
        // setRefPosts(response.data.posts)
        setIsLoading(false)
      }
    })
  }
  const [isSearchActive, setIsSearchActive] = useState(false)
const [searchedPosts, setSearchedPosts] = useState([])
const handleSearch = (post) =>{
    if(post!==''){
    const filterCourse =  posts.filter(p=>p.postTitle.toLowerCase().includes(post.toLowerCase()))
    setSearchedPosts(filterCourse)
    console.log(filterCourse)
    setIsSearchActive(true)
  }
  else{
    setSearchedPosts([])
    setIsSearchActive(false)

  }
}

// const handleCategory = (category) =>{
//   if(category === 'Home'){
//     setRefPosts(posts)
//   }
//   else{
//     const filterCategory = posts.filter(post=>post.postCategory === category)
//     setRefPosts(filterCategory)
//   }

// }
  return (
    <div className='homeContainer'>
      <MetaTags>
        <title>Hardware</title>
        <meta id="meta-description" name="description" content="Where the Internet is about availability of information, blogging is about making information creation available to anyone." />
        <meta id="og-title" property="og:title" content="Cappricio Securities - Hardware blogs" />
        <meta id="og-image" property="og:image" content="https://cappriciosec.com/logo.png" />
      </MetaTags>
        {/* <PostsContainer/> */}
        {/* <Header onChangeCategory = {handleCategory}/> */}
        <Header/>
    {/* <MetaHelmet ogTitle = {"Cappricio Securities"} ogDescription = {"Checkout More Stories"}  ogImage = {"https://cappriciosec.com/logo.png"}/> */}

        <Search searchQuery = {handleSearch}/>
{
  isLoading ? <div className='preloader'>
  <ThreeDots 
height="80" 
width="80" 
radius="9"
color="#4fa94d" 
ariaLabel="three-dots-loading"
wrapperStyle={{}}
wrapperClassName=""
visible={true}
 />
  </div> : posts.length === 0 ? <div className='noPosts'>
    <p>No Posts</p>
  </div> : isSearchActive === false ? <PostsContainer key = {posts.length} posts = {posts}/> : searchedPosts.length === 0 ? <div className='noPosts'>
    <p>No Posts</p>
  </div> :  <PostsContainer key = {posts.length} posts = {searchedPosts}/>
}    
      <Footer/>
    </div>
  )
}

export default Hardware
