import React, {  } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css"
import AdminLogin from "./components/Admin/AdminLogin";
import AdminPanel from "./components/Admin/AdminPanel";
import PostContent from "./components/PostContent/PostContent";
import Home from "./pages/Home";
import Application from "./pages/Application";
import Hardware from "./pages/Hardware";
import Tools from "./pages/Tools";
import Cve from "./pages/Cve";

const App = () =>{
 
  return(
    <BrowserRouter> 
    <Routes>
    <Route exact path="/" Component={Home}/>
    <Route exact path="/application" Component={Application}/>
    <Route exact path="/hardware" Component={Hardware}/>
    <Route exact path="/tools" Component={Tools}/>
    <Route exact path="/cve" Component={Cve}/>
      <Route path = "/:category/:postID/:title" Component={PostContent}/>
    <Route exact path="/adminlogin" Component={AdminLogin}/>
    <Route exact path="/admin" Component={AdminPanel}/>

    </Routes>

    </BrowserRouter>

  )
}

export default App;