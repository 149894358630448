import React from 'react'
import "./AdminHeader.css"
import axios from "axios"
const AdminHeader = () => {

  const logoutHandler = () =>{
    const fetchLogout = async () => {
      try {
        const response = await axios.get('https://blogs.cappriciosec.com/logout.php'); // Adjust the API endpoint URL accordingly
        if(response.data === 'logout...'){
            localStorage.removeItem('admin');
            window.location.href = '/adminlogin'
        }
        console.log(response)
      } catch (error) {
        // Handle error
      }
    };
    fetchLogout()

  }
  return (
    <div className='adminHeader-wrapper'>
        <div className='heading'>
        <img src='https://cappriciosec.com/logo.png' alt=''/>
        <p>CappricioSec Blog</p>
      </div>
      <div className='logout-button'>
        <button 
        onClick={logoutHandler}>LOGOUT</button>
      </div>
     
    </div>
  )
}

export default AdminHeader
