import React, { useEffect, useState } from 'react'
import AddPost from './AddPost/AddPost'
import AdminHeader from './AdminHeader/AdminHeader'
import ViewPost from './ViewPost/ViewPost'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom';
const AdminPanel = () => {
const [view, setView] = useState(true)
const [editData, setEditData] = useState(null)

const activeAdd = (bool) =>{
  setView(bool)
  setEditData(null)

}

const activeEdit = (data) =>{
    setView(data.bool)
    setEditData(data)

}

const cancel = (bool) =>{
  setView(bool)
  window.localStorage.href = '/admin'
}
const dummyData = {
  'postId' : "",
  'postTitle' : "",
  'postImage' : "",
  'postDescription' : "",
  'postCategory' : "",
  'postContent' : "",
  'bool' : "",
  'edit' : false,
}


const [admin, setAdmin] = useState('admin')
useEffect(()=>{
  setAdmin(JSON.parse(localStorage.getItem('admin')))
},[])
  return (
    <div>
    {
admin === 'admin' ?   <>
      <AdminHeader/>
     {view === true ? <ViewPost onActiveAdd = {activeAdd} onActiveEdit = {activeEdit} /> : (editData !== null) ? <AddPost onCancel = {cancel} onEditData = {editData}/> : <AddPost onCancel = {cancel} onEditData = {dummyData}/>}
    <ToastContainer />
</> : <Navigate to = '/adminlogin'/>
    }
 
    </div>
  )
}

export default AdminPanel
